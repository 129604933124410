import AddressInput from "../../components/Address/AddressInput";
import {tShoppingCartContext} from "../../api/context/ShoppingCartContext";
import {eDeliveryType} from "../../api/models/cartModel";
import {useStoreContext} from "../../api/context/StoreContext";
import {t} from "i18next";

interface ContactInfoFormProps {
    shoppingCart: tShoppingCartContext;
}

export const ContactInfoForm = ({shoppingCart}: ContactInfoFormProps) => {
    if (shoppingCart.cart.customer === undefined) {
        shoppingCart.cart.customer = {
            userId: "",
            name: "",
            email: "",
            phone: "",
        };
    }
    return (
        <section>
            <h3>{t("contact")}</h3>

            {/* Nombre */}
            <div className="form-floating mb-3">
                <input
                    id="name"
                    value={shoppingCart.cart.customer.name}
                    type="text"
                    required
                    className={"form-control"}
                    placeholder={t("full_name_placeholder")}
                    onChange={(e) => {
                        shoppingCart.setCustomer({
                            ...shoppingCart.cart.customer,
                            name: e.currentTarget.value,
                        });
                    }}
                />
                <label htmlFor="name">{t("full_name")}</label>
            </div>

            {/* eMail */}
            <div className="form-floating mb-3">
                <input
                    id="email"
                    value={shoppingCart.cart.customer.email}
                    type="email"
                    className="form-control"
                    onChange={(e) => {
                        shoppingCart.setCustomer({
                            ...shoppingCart.cart.customer,
                            email: e.currentTarget.value,
                        });
                    }}
                />
                <label htmlFor="email">{t("email")}</label>
            </div>

            {/* Telefono */}
            <div className="form-floating">
                <input
                    id="phone"
                    value={shoppingCart.cart.customer.phone}
                    type="tel"
                    pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                    required
                    className={"form-control "}
                    placeholder={t("phone_placeholder")}
                    onChange={(e) => {
                        shoppingCart.setCustomer({
                            ...shoppingCart.cart.customer,
                            phone: e.currentTarget.value,
                        });
                    }}
                />
                <label htmlFor="name">{t("phone")}</label>
                <div className="invalid-feedback">{t("phone_invalid")}.</div>
            </div>
        </section>
    );
};

interface DeliveryFormProps {
    shoppingCart: tShoppingCartContext;
}

export const DeliveryForm = ({shoppingCart}: DeliveryFormProps) => {
    const {store} = useStoreContext();

    return (
        <>
            <section>
                <h3 className="mt-0">{t("delivery_type")}</h3>
                <div>
                    {store.pickupEnabled && (
                        <div className="form-check form-check-custom">
                            <input
                                className="form-check-input form-check-input-custom"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault2"
                                checked={
                                    shoppingCart.cart.deliveryType ===
                                    eDeliveryType.Pickup
                                }
                                onChange={(e) =>
                                    shoppingCart.setDeliveryType(
                                        eDeliveryType.Pickup
                                    )
                                }
                            />
                            <label
                                className="form-check-label form-check-label-custom"
                                htmlFor="flexRadioDefault2">
                                {t("delivery_pickup")}
                            </label>
                        </div>)}

                    {store.deliveryEnabled && (
                        <div className="form-check" style={{
                            marginBottom: "2rem", // Espacio entre este radio y el siguiente
                        }}>
                            <input
                                className="form-check-input form-check-input-custom"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault1"
                                checked={
                                    shoppingCart.cart.deliveryType ===
                                    eDeliveryType.Delivery
                                }
                                onChange={(e) =>
                                    shoppingCart.setDeliveryType(
                                        eDeliveryType.Delivery
                                    )
                                }
                            />
                            <label
                                className="form-check-label form-check-label-custom"
                                htmlFor="flexRadioDefault1"
                                style={{
                                    lineHeight: "1.8", // Aumenta la altura de línea del label para alinearlo al radio
                                }}
                            >
                                {t("delivery_delivery")}
                            </label>
                        </div>
                    )}
                </div>
            </section>

            {shoppingCart.cart.deliveryType === eDeliveryType.Delivery && (
                <section>
                    <h3>{t("delivery_address")}</h3>
                    <AddressInput
                        address={shoppingCart.cart.deliveryAddress}
                        onChange={(address) => shoppingCart.setAddress(address)}
                    />
                </section>
            )}
        </>
    );
};
